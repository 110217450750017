<template>
  <b-row no-gutters class="award__wrapper" :class="{ active: active }">
    <b-col cols="12" md="8" lg="10" @click="viewCredentials">
      <div class="d-flex">
        <div class="award-icon">
          <img :src="active ? awardIcon : awardLockedIcon" alt="Award" />
        </div>
        <div>
          <div class="award-title">
            {{ credentials.section_title }}
          </div>
          <div
            v-if="active"
            class="award-type c-pointer d-md-none d-block mb-2"
            @click="viewCredentials"
          >
            {{ $t("course.overview.assessment") }}
          </div>
          <div v-else class="award-type  d-md-none d-block mb-2">
            {{ $t("course.overview.assessment") }}
          </div>
          <div class="award-details">
            <div class="award-completion-date">
              <img
                :src="active ? awardCompletion : awardCompletionDisabled"
                alt="Award completion"
              />
              <span v-if="active" class="mr-2"
                >{{
                  credentials.instance.created_at | formatDate("MMM Do YYYY")
                }}
              </span>
            </div>
            <div class="award-validity">
              {{ $t("achievements.microcredential.valid_for") }}
              {{
                $t("achievements.microcredential.x_years_other", {
                  count: credentials.valid_for
                })
              }}
            </div>
          </div>
        </div>
      </div>
    </b-col>
    <b-col cols="12" md="4" lg="2" class="d-md-block d-none">
      <div v-if="active" class="award-type c-pointer" @click="viewCredentials">
        {{
          credentials.award_type === "PASS_ASSESSMENT"
            ? $t("course.overview.assessment")
            : $t("course.overview.activity")
        }}
      </div>
      <div v-else class="award-type">
        {{
          credentials.award_type === "PASS_ASSESSMENT"
            ? $t("course.overview.assessment")
            : $t("course.overview.activity")
        }}
      </div>
    </b-col>
  </b-row>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  props: {
    active: {
      type: Boolean,
      default: true
    },
    credentials: {
      type: [Array, Object],
      required: true
    },
    id: {
      type: String
    }
  },
  data() {
    return {
      awardIcon: require("@/assets/images/course-details/award.svg"),
      awardLockedIcon: require("@/assets/images/course-details/award-locked.svg"),
      awardCompletion: require("@/assets/images/course-details/award-completion.svg"),
      awardCompletionDisabled: require("@/assets/images/course-details/award-completion-diasbled.svg")
    };
  },
  computed: {
    ...mapGetters([
      "getCurrentUnitId",
      "isPrerequisiteCompleted",
      "getCurrentSection",
      "language",
      "getSelectedCourse"
    ])
  },
  methods: {
    viewCredentials() {
      if (this.id !== null) {
        this.$router.push({
          path: `/awards/${this.id}/credential`,
          query: {
            key: this.credentials.instance.key,
            lang: this.language,
            id: this.id,
            from: "course-detail"
          }
        });
      }
    }
  }
};
</script>

<style lang="scss">
$bg-1-background-color: #fef8e6;
$bg-2-background-color: $brand-secondary2-400;
$bg-3-background-color: $brand-secondary2-400;

.award__wrapper {
  padding: 16px;
  margin: 0 24px 16px 0 !important;
  box-shadow: 0px 4px 8px 4px rgba(33, 33, 33, 0.1);
  border-radius: 4px;
  background: #ffffff;
  &.active {
    background: $bg-1-background-color;
    cursor: pointer;
    .award-title {
      color: $brand-neutral-900;
    }
    .award-type {
      color: $bg-1-background-color;
      background: $bg-2-background-color;
    }
  }

  .award-icon {
    align-self: center;
    margin-right: 20px;
  }

  .award-title {
    @include subtitle-regular;
    margin-bottom: 8px;
    color: $brand-neutral-200;
  }
  .award-details {
    display: flex;
    text-align: left;

    .award-completion-date,
    .award-validity {
      @include label-small;
    }
    .award-completion-date {
      color: $brand-neutral-700;
      img {
        margin-right: 8px;
      }
    }
    .award-validity {
      color: $brand-neutral-200;
    }
  }
  .award-type {
    width: fit-content;
    margin-left: auto;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 0.5px;
    border-radius: 8px;
    padding: 4px 8px;
    color: $brand-neutral-200;
    background: $brand-neutral-75;
  }
}

@media screen and (max-width: 768px) {
  .award__wrapper {
    .award-type {
      margin-left: 0;
    }
  }
}

[dir="rtl"] {
  .award__wrapper {
    .award-validity {
      padding-right: 10px;
    }
    .award-title {
      padding-right: 20px;
    }
    .award-icon {
      margin-right: 0px;
    }
    .award-completion-date {
      padding-right: 10px;
    }
    .award-type {
      margin-right: auto;
    }
  }
  @media screen and (max-width: 768px) {
    .award__wrapper {
      .award-type {
        margin-right: 0;
      }
    }
  }
}
</style>
